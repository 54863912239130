<template>
  <div class="bac">
    <div class="main">
      <div class="head">
        <div>
          <a>收到的评论</a>
          <i></i>
        </div>
      </div>
      <div class="content">
        <div class="tableColumn">
          <div class="pic">
            <img src="../../../../images/temporary.png" alt="" style="width: 4.95rem; height: 3.06rem;">
          </div>
          <div class="top">
            <div class="data">
              <div class="dataDay">
                22
              </div>
              <div class="dataMonth">
                2021.12
              </div>

              <!--              <div class="recruit">-->

              <!--              </div>-->
            </div>
            <div class="name">
              水处理设备
            </div>
            <div class="bottom">
              <div class="num">
                <p>招募人数</p>
                <p>3</p>
              </div>
              <div class="deadLine">
                <p>剩余时间</p>
                <p>4天</p>
              </div>
              <div class="budget">
                <p>项目预算</p>
                <p>50000</p>
              </div>
            </div>
            <button>查看详情</button>
          </div>
        </div>
        <div class="comment">
          <div class="tx"></div>
          <div class="info">
            <div class="info_top">
              <div class="name">用户12121</div>
              <div class="years">2022-01-02</div>
              <div class="time">11:50:46</div>
            </div>
            <div class="info_bottom">
              <input type="text" value="太赞了!">
            </div>
          </div>
        </div>
        <div class="comment">
          <div class="tx"></div>
          <div class="info">
            <div class="info_top">
              <div class="name">用户12121</div>
              <div class="years">2022-01-02</div>
              <div class="time">11:50:46</div>
            </div>
            <div class="info_bottom">
              <input type="text" value="太赞了!">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Fans"
}
</script>

<style scoped>

.bac{
  width: 19.2rem;
  /*height: 10.8rem;*/
  padding-left: 2.98rem;
  background-color: #f5f5f5;
}

.main{
  float: left;
  width: 14.58rem;
  height: 7.96rem;
  margin-left: .66rem;
  margin-top: .72rem;
  background-color: #ffffff;
  border: .01rem solid transparent;
}

.main .head{
  width: 14rem;
  height: .96rem;
  margin-left: .3rem;
}

.head div{
  width: 1.52rem;
  height: .96rem;
  /*font-size: .28rem;*/
  /*color: #ec414d;*/
}

.head div a{
  display: block;
  width: 1.52rem;
  height: .94rem;
  line-height: .96rem;
  font-size: .28rem;
  text-align: center;
  color: #ec414d;
}

.head div i{
  display: block;
  width: 1.52rem;
  height: .02rem;
  background-color: #ec414d;
}

.main .content{
  width: 14.58rem;
  height: 6.68rem;
}


/*display部分css开始*/
.tableColumn{
  width: 14.58rem;
  height: 4.74rem;
  background-color: #ffffff;
  margin: 0 auto;
  /*border-bottom: .02rem solid #faf0ee;*/
  padding-top: .5rem;

}

.tableColumn .top{
  float: left;
  width: 8.52rem;
  height: 1.28rem;
}

.tableColumn .pic{
  float: left;
  width: 4.95rem;
  height: 3.06rem;
  /*background-color: #ec414d;*/
  margin-left: .32rem;
}

.tableColumn .pic img{
  width: 100%;
  height: 100%;
}

.tableColumn .data{
  float: left;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: .75rem;
  /*margin-top: 1.05rem;*/
  text-align: center;
  line-height: 1.25rem;
  color: #ec414d;
  /*background-color: #1ea1e0;*/
  border-radius: .05rem;
  box-shadow: 0rem 0rem .05rem rgba(0,0,0,.2);
}

.tableColumn .dataDay{
  height: .4rem;
  font-size: .4rem;
  font-weight: 700;
  color: #ec414d;
  /*margin-bottom:.15rem;*/
  margin-top: -0.1rem;
}

.tableColumn .dataMonth{
  height: .21rem;
  font-size: .21rem;
  color: #ec414d;
}

.tableColumn .name{
  float: left;
  height: .36rem;
  width: 3.6rem;
  line-height: .36rem;
  font-size: .36rem;
  color: #212121;
  font-weight: 500;
  margin-left: .3rem;
  margin-top: .32rem;
}

.tableColumn .recruit{
  float: left;
  margin-left: .96rem;
  width: .9rem;
  height: .32rem;
  font-size: .16rem;
  text-align: center;
  line-height: .32rem;
  background-color: #ff6460;
  color: #ffffff;
  border-radius: .05rem;
  margin-top: .4rem;
}

.tableColumn .bottom{
  float: left;
  width: 8.52rem;
  height: .69rem;
  margin-top:.45rem;
}

.tableColumn .num{
  float: left;
  width: .85rem;
  height: .75rem;
  /*margin-left: -5.4rem;*/
  /*margin-top: 1rem;*/
  margin-left: 2.3rem;
  /*background-color: #1ea1e0;*/
  text-align: center;
}

.tableColumn .num p:nth-child(1){
  font-size: .21rem;
  color: #9e9e9e;
  margin-bottom: .22rem;
}

.tableColumn .num p:nth-child(2){
  font-size: .32rem;
  color: #212121;
  /*margin-bottom: .27rem;*/
}

.tableColumn .deadLine{
  float: left;
  width: .85rem;
  height: .75rem;
  /*margin-left: -5.4rem;*/
  /*margin-top: 1rem;*/
  margin-left: 1.06rem;
  text-align: center;
  /*background-color: #1ea1e0;*/

}

.tableColumn .deadLine p:nth-child(1){
  font-size: .21rem;
  color: #9e9e9e;
  margin-bottom: .22rem;
}

.tableColumn .deadLine p:nth-child(2){
  font-size: .32rem;
  color: #212121;
  /*margin-bottom: .27rem;*/
}

.tableColumn .budget{
  float: left;
  width: .85rem;
  height: .75rem;
  /*margin-left: -5.4rem;*/
  /*margin-top: 1rem;*/
  margin-left: 1.06rem;
  text-align: center;
  /*margin-bottom: .1rem;*/
  /*background-color: #1ea1e0;*/

}

.tableColumn .budget p:nth-child(1){
  font-size: .21rem;
  color: #9e9e9e;
  margin-bottom: .22rem;
}

.tableColumn .budget p:nth-child(2){
  font-size: .32rem;
  color: #212121;
  /*margin-bottom: .27rem;*/
  /*margin-bottom: .2rem;*/
}

.tableColumn button{
  width: 1.93rem;
  height: .68rem;
  margin-top: .68rem;
  margin-left: 6rem;
  color: #fef6f6;
  background-color: #212121;
  border-radius: .03rem;
  font-size: .24rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#ec414d 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all .7s;
}

.tableColumn button:hover{
  background-size: 300%;
}

/*dispaly部分的css代码结束*/

.content .tidai{
  width: 14.58rem;
  height: 4.36rem;
  background-color: pink;
}

.content .comment{
  width: 11.65rem;
  height: .86rem;
  margin-left: .96rem;
  margin-bottom: .24rem;
}

.content .comment .tx{
  float: left;
  width: .86rem;
  height: .86rem;
  border-radius: 50%;
  /*background-color: pink;*/
  margin-right: .24rem;
  background-image: url("../../../../images/tx.png");
  background-size: 100% 100%;
}

.content .comment .info{
  float: left;
  width: 10.55rem;
  height: .86rem;
}

.content .info .info_top{
  width: 10.55rem;
  height: .22rem;
  margin-bottom: .17rem;
}

.content .info .info_top .name{
  float: left;
  font-size: .18rem;
  color: #212121;
  margin-right: .24rem;
  font-weight: 500;
}

.content .info .info_top .years{
  float: left;
  font-size: .18rem;
  color: #676364;
  margin-right: .12rem;
}

.content .info .info_top .time{
  float: left;
  font-size: .18rem;
  color: #676364;
}

.content .info .info_bottom{
  width: 10.55rem;
  height: .64rem;
}

.content .info .info_bottom input{
  width: 10.55rem;
  height: .48rem;
  padding-left: .3rem;
  color: #676364;
  font-size: .18rem;
  border: .01rem solid #676364;
}


</style>